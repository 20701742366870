<template>
  <section class="full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">IVS Channels</div>
      </div>
    </div>
    
    <div class="columns is-centered is-gapless is-marginless">
      <b-button class="button is-primary" @click="CreateChannel()">
        <strong>Create Channel</strong>
      </b-button>
    </div>
    
    
    <div class="columns is-multiline is-centered is-gapless is-marginless" v-if="NewChannelInfo.arn">
      <div class="column is-10 is-offset-1" style="margin-bottom: 10px">
         ARN: {{ NewChannelInfo.arn }}
      </div>
      
      <div class="column is-10 is-offset-1" style="margin-bottom: 10px">
         {{ NewChannelInfo.rtmps }}
      </div>
      
      <div class="column is-10 is-offset-1" style="margin-bottom: 10px">
         StreamKey: {{ NewChannelInfo.Streamkey }}         
      </div>
      
      <div class="column is-10 is-offset-1" style="margin-bottom: 10px; color: blue">           
        PlaybackUrl: <a :href="`${rootPath}/Video/${encodeURIComponent(NewChannelInfo.PlaybackUrl)}`" target="_blank">
          {{ NewChannelInfo.PlaybackUrl }}
        </a>
      </div>
      
      <div class="column is-10 is-offset-1" style="margin-bottom: 10px">
         S3: {{ NewChannelInfo.S3 }}
      </div>
    </div>
    
    
    
    <b-table v-if="ivsdata"
    
                :data="ivsdata"
                :bordered="true"
                :striped="true"
                :narrowed="false"
                :hoverable="true"

                :loading="false"
                :focusable="true"

                :header-checkable="false"
                :mobile-cards="true"
                
                >
                <!-- checkable -->
                <!-- :checked-rows.sync="tbl1_checkedRows" -->
                
                <template>
                    <b-table-column field="name" label="name" width="400" v-slot="props" centered>  
                      {{ props.row.name }}
                    </b-table-column>
                    <b-table-column field="arn" label="arn" width="400" v-slot="props" centered>  
                      {{ props.row.arn }}
                    </b-table-column>
                    
                    <b-table-column field="action" label="action" width="100" v-slot="props" centered>  
                      <b-button class="button is-primary" @click="DeleteChannel(props.row)">
                        <strong>Delete</strong>
                      </b-button>
                    </b-table-column>
                    
                    
                    
                </template>
                


                <template slot="empty">
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>
                                <b-icon
                                    icon="emoticon-sad"
                                    size="is-large">
                                </b-icon>
                            </p>
                            <p>Geen IVS Channels gevonden.</p>
                        </div>
                    </section>
                </template>
            </b-table>
    
   
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';
// import { get_doubleDigits } from '@/helpers/functions';

export default {
  name: "Streams", 
         
  data() {
    return {
      pageLoading : false,
      pageLoadingTimeout : null,
      
      ivsdata: null,
      NewChannelInfo: {
        arn: '',
        rtmps: '',
        Streamkey: '',
        PlaybackUrl: '',
        S3: '',
      },
      
      rootPath: "",
    }
  },
  
  created(){
    this.rootPath = process.env.VUE_APP_WEB_ROOT
  },
  
  async mounted(){
    this.GetChannelList();  
  },
  
  methods: {
    async GetChannelList() {
      const ivsdata = await this.$store.dispatch('ivs_actions/IVS_Get_Channels');   
      this.ivsdata = ivsdata.ivslist.channels;
      console.info(`ivsdata:`, this.ivsdata);
    },
    
    async DeleteChannel(channel) {
      console.info(`channel:`, channel)
      const _this = this;
      this.$buefy.dialog.confirm({
          message: `Are you sure you want to delete Channel: ${channel.name}`,
          onConfirm: async () => {
            await _this.$store.dispatch('ivs_actions/IVS_Delete_Channel', channel.arn );
            _this.GetChannelList();
            _this.clearchannelInfo()
          }
      });
      
      
    },
    
    clearchannelInfo() {
      this.NewChannelInfo = {
        arn: '',
        rtmps: '',
        Streamkey: '',
        PlaybackUrl: '',
        S3: '',
      }
    },
    
    async CreateChannel() {
      const _this = this;
      this.$buefy.dialog.prompt({
          message: `Channel Name`,
          inputAttrs: {
            placeholder: 'Channel Name',
            maxlength: 100
          },
          trapFocus: true,
          onConfirm: async (value) => {
            this.$buefy.toast.open(`Channel Name: ${value}`);
            let rtn = await _this.$store.dispatch('ivs_actions/IVS_Create_Channel', value);
            console.info(`rtn:`, rtn);
            
            _this.NewChannelInfo = rtn.channelData.extra;
            _this.GetChannelList();
          }
      })
    },
     
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
        
    
  },
};
</script>

<style scoped>
  
                        
</style>